<template>
  <div>
    <Card :title="title">
      <div class="thumb-container">
        <template v-if="html == ''">
          <div class="thumb-card h-100">
            <fa-icon
              icon="exclamation-circle"
              class="text-warning icon-style"
            ></fa-icon>
            <p class="text-muted">
              Nenhum {{ title.toLowerCase() }} selecionado
            </p>
          </div>
        </template>
        <template v-else>
          <iframe class="thumbnail" :srcdoc="html" />
        </template>
      </div>
      <hr />
      <div class="mb-3 d-flex justify-content-end">
        <b-button @click="$refs.sliderModal.open()">Selecionar</b-button>
      </div>
    </Card>
    <SweetModal
      ref="sliderModal"
      overlay-theme="dark"
      width="80%"
      hide-close-button
      blocking
    >
      <template #title>
        <strong>Selecione o {{ title }}</strong>
      </template>

      <template v-if="templates.length > 0" class="w-100">
        <slider class="p-2 w-100" ref="slider" :options="options">
          <slideritem
            v-for="item in templates"
            :key="item.id"
            :style="{ width: '33%', 'margin-right': '2%' }"
          >
            <Card :class="[{ isActive: value == item.id }]">
              <div
                @click="check(item.id)"
                class="thumb-container py-3"
                slot="card-body"
                style="cursor: pointer"
              >
                <div class="thumbnail" v-html="item.html" />
              </div>
            </Card>
          </slideritem>
        </slider>
        <div class="d-flex mt-4 justify-content-center">
          <b-button
            @click="$refs.slider.$emit('slidePre')"
            pill
            class="mr-2"
            variant="success"
            ><fa-icon icon="arrow-left"
          /></b-button>
          <b-button
            @click="$refs.slider.$emit('slideNext')"
            pill
            class="mr-2"
            variant="success"
          >
            <fa-icon icon="arrow-right"
          /></b-button>
        </div>
      </template>
      <template v-else>
        <div class="thumb-card h-100">
          <fa-icon
            icon="exclamation-circle"
            class="text-warning icon-style"
          ></fa-icon>
          <p class="text-muted">
            Nenhum {{ title.toLowerCase() }} criado, clique no botão "Criar Novo
            Cabeçalho", para criar um novo cabeçalho.
          </p>
        </div>
      </template>
      <template #button>
        <b-button class="mr-2" variant="info" @click="create_new()"
          >Criar Novo {{ title }}</b-button
        >
        <b-button
          v-if="templates.length > 0"
          @click="open_preview"
          class="mr-2"
          variant="info"
          >Visualizar Selecionado</b-button
        >
        <b-button @click="$refs.sliderModal.close()" variant="info"
          >Fechar</b-button
        >
      </template>
    </SweetModal>
    <ModalPreviewHtml ref="previewHtml" :previewHtml="html" />
    <ModalCreateTemplate      
      ref="modalCreateTemplate"
      :type="type"
      :application_id="application_id"
      :notification_type_id="notification_type_id"
      @closed="createTemplate=false"
    />
  </div>
</template>

<script>
import { slider, slideritem } from "vue-concise-slider";
export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    notification_type_id: {
      type: String,
      default: null,
    },
    application_id: {
      type: String,
      default: null,
    },
    value: {
      type: String,
    },
  },
  data() {
    return {
      createTemplate: true,      
      html: "",
      options: {
        itemAnimation: true,
        loopedSlides: 1,
        slidesToScroll: 1,
        thresholdDistance: 200,
        pagination: false,
        currentPage: 0,
      },
    };
  },
  components: {
    slider,
    slideritem,
    ModalPreviewHtml: () => import("@/components/ModalPreviewHtml.vue"),
    ModalCreateTemplate: () => import("@/components/ModalCreateTemplate.vue"),
  },
  watch: {
    application_id: {
      handler() {
        this.$emit("input", "");
        this.html = "";
        this.createTemplate = false;
        console.log(this);
        setTimeout(() => {
          this.createTemplate = true;
        }, 500);
      },
    },
    notification_type_id: {
      handler() {
        this.$emit("input", "");
        this.html = "";
        this.createTemplate = false;
        setTimeout(() => {
          this.createTemplate = true;
        }, 500);
      },
    }
  },
  computed: {
    state() {
      return this.$store.state.global;
    },
    templates() {      
      let byType = [];
      byType = _.filter(this.state.templates, (template) => {
        return template.type == this.type;
      });
      let byApplication = [];
      if (this.application_id == null) {
        byApplication = byType;
      } else {
        byApplication = _.filter(byType, (template) => {
          return template.application_id == this.application_id;
        });
      }
      let byNotification = [];
      if (this.notification_type_id == null) {
        byNotification = byApplication;
      } else {
        byNotification = _.filter(byApplication, (template) => {
          return template.notification_type_id == this.notification_type_id;
        });
      }
      const sorted = _.sortBy(byNotification, [
        "application_name",
        "notification_type_id",
      ]);
      return sorted;
    },
    templatesById() {
      return _.keyBy(this.templates, "id");
    },
    title() {
      if (this.type == "header") {
        return "Cabeçalho";
      } else {
        return "Rodapé";
      }
    },
  },
  methods: {
    check(event) {
      const selected = this.templatesById[event];
      this.$emit("input", event);
      this.html = selected.html;
    },
    open_preview() {
      this.$refs.previewHtml.open();
    },
    close_preview() {
      this.$refs.preview_modal.close();
    },
    slideIndex(idx) {
      this.options.currentPage = idx.currentPage;
    },
    create_new() {
      this.$refs.modalCreateTemplate.open()  
    }    
  },
  mounted() {
    if (this.value) {
      this.html = this.templatesById[this.value].html;
    }
  },
};
</script>

<style lang="scss" scoped>
.thumb-container {
  height: 30vh;
}
.thumb-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.thumbnail {
  position: relative;
  -ms-zoom: 0.35;
  -moz-transform: scale(0.35);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.35);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.35);
  -webkit-transform-origin: 0 0;
  width: 285%;
  border: none;
  height: 65vh;
}
.check-img {
  width: 25px;
  height: 25px;
  margin-right: 10px !important;
}
.isActive {
  border: 1px solid #007bff;
}
.icon-style {
  font-size: 3rem;
  margin-bottom: 1rem;
}
::v-deep .sweet-content-content {
  width: 100% !important;
}
</style>